import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

import { Link } from "gatsby";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  "Juridische Mededeling": "nl/juridische-mededeling"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "de",
    href: "/de/allgemeine-geschaeftsbedingungen"
  },
  {
    hreflang: "en",
    href: "/en/legal-notice"
  },
  {
    hreflang: "x-default",
    href: "/en/legal-notice"
  },
  {
    hreflang: "se",
    href: "/se/juridiskt-meddelande"
  },
  {
    hreflang: "da",
    href: "/da/juridisk-meddelelse"
  },
  {
    hreflang: "no",
    href: "/no/juridisk-merknad"
  },
  {
    hreflang: "nl",
    href: "/nl/juridische-mededeling"
  }
];

const LegalNotice = props => {
  return (
    <Layout location={props.location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Juridische Mededelingen"
          description="Juridische mededelingen over de diensten van Matthias Kupperschmidt op het gebied van online marketing; dit omvat zoekmachineoptimalisatie, webanalyse-implementaties, consultancy op het gebied van online marketing"
          lang="nl"
          alternateLangs={alternateLangs}
        />
        <MainContent
          article={false}

        >
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

          <H as="h1">Juridische Mededeling</H>

          <p>Matthias Kupperschmidt biedt verschillende diensten aan (hierna “diensten” genoemd) op het gebied van online marketing; dit omvat zoekmachineoptimalisatie, webanalyse-implementaties, consultancy op het gebied van online marketing, evenals trainingen en workshops.</p>

          <p>Het gebruik van de aangeboden diensten is alleen toegestaan voor het beoogde doel dat in deze Algemene Voorwaarden en/of de opdracht wordt beschreven. Daarnaast is elk ander gebruik of verwerking alleen toegestaan onder de strikte voorwaarden van de Deense wetgeving inzake gegevensbescherming, met name de Wet op de Gegevensbescherming en de Algemene Verordening Gegevensbescherming (AVG) van de EU. Elk ongeoorloofd gebruik of exploitatie is niet toegestaan. In het bijzonder is het volledige gebruik van de aangeboden diensten en inhoud voor commerciële doeleinden van welke aard dan ook en in welke media dan ook (gedrukt, elektronisch, op CD, etc.), evenals het uitlezen van directories, databases, etc., voor de genoemde doeleinden of voor andere commerciële doeleinden niet toegestaan en zal door de aanbieder worden vervolgd volgens de toepasselijke wetgeving door gebruik te maken van alle beschikbare juridische middelen.</p>
          <p>De beschikbaarheid van individuele diensten evenals de juistheid en volledigheid van de inhoud/invoer/gegevens wordt niet gegarandeerd.</p>
          <p>Deze Algemene Voorwaarden regelen de betrekkingen tussen Matthias Kupperschmidt en zijn klanten en contractuele partners (hierna “contractuele partner” genoemd).</p>

          <p>Sectie 1 Toepassingsgebied</p>
          <p>De diensten van Matthias Kupperschmidt worden uitsluitend uitgevoerd op basis van deze Algemene Voorwaarden. Daarom zijn ze ook van toepassing op alle toekomstige zakelijke relaties, zelfs als ze niet opnieuw uitdrukkelijk zijn overeengekomen.</p>
          <p>Het gebruik van de door Matthias Kupperschmidt aan de contractuele partner aangeboden diensten wordt uitsluitend geregeld door deze Algemene Voorwaarden (AV) evenals de speciale voorwaarden van de respectieve producten/diensten. Voor aanbiedingen, diensten, individuele projecten, promoties en andere diensten, vooral projectdiensten daarbuiten, gelden de voorwaarden die in afzonderlijke overeenkomsten worden overeengekomen.</p>
          <p>De algemene voorwaarden van contractuele partners of andere derden zijn niet van toepassing, zelfs als Matthias Kupperschmidt deze niet uitdrukkelijk afwijst en/of zijn diensten zonder bezwaar verleent.</p>

          <p>Sectie 2 Algemene omvang van diensten</p>
          <p>Matthias Kupperschmidt is onder andere actief op het gebied van zoekmachineoptimalisatie, webanalyse, inbound marketing, consultancy op het gebied van online marketing, evenals trainingen en workshops. De gedetailleerde beschrijving van de in elk geval bestelde dienst is te zien in de respectieve bestelling.</p>
          <p>De door Matthias Kupperschmidt aangeboden diensten en inhoud worden verstrekt en gepubliceerd door Matthias Kupperschmidt, tenzij anders aangegeven. Alle inhoud die door derden toegankelijk wordt gemaakt, is informatie verstrekt door de respectieve maker, auteur of distributeur en niet door Matthias Kupperschmidt. Matthias Kupperschmidt is daarom niet verantwoordelijk voor de juistheid of betrouwbaarheid van deze inhoud.</p>
          <p>De door Matthias Kupperschmidt aangeboden diensten zijn alleen een uitnodiging om een voorstel in te dienen om een contract te sluiten. De contractuele partner is tien werkdagen gebonden aan zijn verklaring. Voor de totstandkoming van een contract is bevestiging in tekstvorm (e-mail volstaat) door Matthias Kupperschmidt vereist. Een zakelijke overeenkomst komt dan tot stand op basis van de toepasselijke AV.</p>
          <p>Matthias Kupperschmidt is niet verplicht om contracten te sluiten. Hij is gerechtigd om de totstandkoming van contracten zonder opgave van redenen te weigeren. Latere wijzigingen en aanvullingen van de contracten vereisen de schriftelijke bevestiging van Matthias Kupperschmidt. Voor verdere diensten moeten de contractuele partners afzonderlijke overeenkomsten aangaan.</p>
          <p>Matthias Kupperschmidt behoudt zich ook het recht voor om contractuele partners later uit te sluiten van het gebruik van de diensten als er aanwijzingen zijn dat de contractuele partner de diensten misbruikt of gebruikt op een manier die de reputatie van Matthias Kupperschmidt in gevaar kan brengen. Dit geldt met name in het geval dat de contractuele partner zijn verplichtingen uit de contractuele relatie schendt of andere contractuele partners van Matthias Kupperschmidt schaadt.</p>
          <p>Voor zover een contractuele partner software van de respectieve andere contractuele partner gebruikt als onderdeel van het gebruik van de diensten, verleent de respectieve andere contractuele partner hem – tenzij uitdrukkelijk anders geregeld – een niet-exclusief, niet-overdraagbaar gebruiksrecht, voor eigen gebruik, dat beperkt is in tijd tot de duur van het contract en, qua inhoud, beperkt is tot gebruik in de zin van de verlening van de dienst. Verdere gebruiksrechten worden niet aan de contractuele partner verleend.</p>
          <p>Voor onderhoudsdoeleinden en om reparaties uit te voeren, is Matthias Kupperschmidt gerechtigd om de verlening van diensten of delen daarvan te onderbreken voor zover dat redelijk is voor de contractuele partner. In het algemeen zal Matthias Kupperschmidt ernaar streven onderhouds- en reparatiewerkzaamheden uit te voeren op tijden waarop de belastende impact daarvan zo laag mogelijk zal zijn. Dit geeft geen recht op verhaal of compensatie aan de zijde van de contractuele partner, tenzij Matthias Kupperschmidt schuld treft.</p>
          <p>Matthias Kupperschmidt is gerechtigd om vergelijkbare contractuele relaties aan te gaan met concurrenten van de contractuele partner. Matthias Kupperschmidt zal echter – zonder verplicht te zijn – proberen de gerechtvaardigde belangen van de contractuele partner in dit opzicht te beschermen en belangenconflicten te vermijden. Matthias Kupperschmidt is gerechtigd om gedeeltelijke diensten te leveren evenals diensten door derden.</p>
          <p>Deadlines en data die door de contractuele partner worden gevraagd, zijn alleen bindend wanneer Matthias Kupperschmidt deze uitdrukkelijk schriftelijk heeft bevestigd en als de contractuele partner alle voorwaarden die nodig zijn voor de verlening van de dienst tijdig heeft gecreëerd binnen zijn invloedsfeer.</p>
          <p>Voor zover en zolang de contractuele partner niet aan een van zijn samenwerkingsverplichtingen die vereist zijn voor de verlening van de dienst heeft voldaan, zelfs na een schriftelijk verzoek van Matthias Kupperschmidt en het stellen van een redelijke respijtperiode, wordt Matthias Kupperschmidt ontslagen van het vervullen van de verplichting die door deze nalatigheid op de afgesproken datum in de projectovereenkomst wordt beïnvloed. De in de bestelling/contract overeengekomen deadline voor levering wordt dienovereenkomstig verlengd.</p>

          <p>Sectie 3 Contractering, omvang van het contract</p>
          <p>Elke dienst moet afzonderlijk worden besteld door de contractuele partner (individuele bestelling/service level agreement). De contracterende partijen zullen elkaar wederzijds voorzien van de inhoud die nodig is voor de uitvoering van de bestelling. Als tracking codes in deze inhoud zijn geïntegreerd, mogen deze alleen worden gewijzigd of verwijderd door de respectieve andere contractuele partner na voorafgaand overleg en instemming.</p>
          <p>Matthias Kupperschmidt zal een factuur maken voor de contractuele partner voor elke uitgevoerde promotie waarin duidelijk alle informatie wordt weergegeven die in Artikel 1 is gespecificeerd.</p>

          <p>Paragraaf 4 Verplichtingen van de contractuele partner</p>
          <p>De klant verbindt zich ertoe de maatregelen van Matthias Kupperschmidt te ondersteunen. De klant zal Matthias Kupperschmidt voorzien van alle informatie en gegevens die nodig zijn voor de uitvoering van de opdracht.</p>
          <p>De contractuele partner verbindt zich ertoe ervoor te zorgen dat de door hem verstrekte inhoud in verband met het gebruik van de diensten van Matthias Kupperschmidt niet in strijd is met de toepasselijke wetgeving van de staat Denemarken; dit geldt in het bijzonder voor illegale, immorele of pornografische inhoud of dergelijke inhoud die oorlog verheerlijkt of geschikt is om een moreel risico te vormen voor kinderen en jongeren of hun welzijn negatief te beïnvloeden.</p>
          <p>De contractuele partner zal ook alle gebeurtenissen en omstandigheden melden die van belang kunnen zijn voor de juiste en tijdige uitvoering van het contract. Dit geldt ook voor gebeurtenissen en omstandigheden waarvan de contractuele partner pas tijdens de looptijd van het contract op de hoogte wordt. Projectvertragingen als gevolg van het feit dat de contractuele partner niet aan de vereiste samenwerkingsverplichtingen voldoet of deze niet tijdig nakomt, zijn voor rekening van de contractuele partner en hebben geen invloed op het vergoedingsrecht van Matthias Kupperschmidt.</p>
          <p>Elke schending van de in punt 1 gespecificeerde verplichting geeft Matthias Kupperschmidt het recht om het contract tussen Matthias Kupperschmidt en de contractuele partner onmiddellijk te beëindigen. Matthias Kupperschmidt behoudt zich het recht voor tot onmiddellijke beëindiging, zelfs als er slechts een vermoeden bestaat dat de contractuele partner de inhoud zoals beschreven in punt 1 verspreidt of tolereert. Matthias Kupperschmidt is gerechtigd maar niet verplicht om de wettelijkheid van de inhoud te controleren.</p>
          <p>De contractuele partner verbindt zich ertoe strikt de technische en inhoudelijke specificaties van Matthias Kupperschmidt na te leven. Eventuele vertragingen en/of wijzigingen met betrekking tot de door Matthias Kupperschmidt geleverde diensten als gevolg van het niet naleven van deze specificaties zijn voor rekening van de contractuele partner en geven geen recht op vorderingen tegen Matthias Kupperschmidt.</p>
          <p>Bovendien is de contractuele partner verantwoordelijk ervoor dat alle informatie en documenten die nodig zijn voor de vervulling van zijn contractuele verplichtingen en de juiste uitvoering van de samenwerking, tijdig, volledig en in een vorm die geschikt is voor vervulling aan Matthias Kupperschmidt worden verstrekt voorafgaand aan de overeengekomen uitvoering van het contract.</p>
          <p>Als een partij wordt verhinderd de contractuele dienst en/of inhoud te leveren door omstandigheden waarvoor zij niet verantwoordelijk is, dient deze partij de betreffende andere partij dienovereenkomstig te informeren en zonder vertraging inhoud en/of diensten over te dragen die aan de omstandigheden zijn aangepast. Als de bovengenoemde omstandigheden langer dan 24 uur duren, zullen de partijen in onderling overleg overeenkomen hoe verder te gaan.</p>
          <p>De contractuele partner heeft niet het recht om de merken en/of logo's van Matthias Kupperschmidt, zijn klanten en partners te gebruiken, te reproduceren, te verspreiden, te publiceren, te wijzigen of anderszins te gebruiken, tenzij Matthias Kupperschmidt hier vooraf uitdrukkelijk schriftelijk toestemming voor heeft gegeven. Bovendien is de contractuele partner verplicht om de auteursrechten of merkenrechten van derden, zowel Duits als buitenlands, te respecteren.</p>
          <p>De contractuele partner stemt in met het doorsturen van zijn inhoud, gegevens en andere informatie naar derden voor zover dit noodzakelijk en vereist is voor de vervulling van de contractuele relatie tussen de partijen.</p>

          <p>Paragraaf 5 Looptijd van het contract/beëindiging</p>
          <p>Kostenramingen van Matthias Kupperschmidt zijn niet bindend. Matthias Kupperschmidt verbindt zich ertoe de contractuele partner onmiddellijk op de hoogte te stellen van eventuele afwijkingen in de werkelijke kosten ten opzichte van de geschatte kosten als de afwijking meer dan tien procent bedraagt.</p>
          <p>Het contract dat is afgesloten tussen Matthias Kupperschmidt en de contractuele partner eindigt met het verstrijken van de overeengekomen termijn. Verder kan de respectieve looptijd van het contract worden afgeleid uit het aanbod.</p>
          <p>Als een minimumtermijn is overeengekomen met de contractuele partner, wordt de overeenkomst automatisch verlengd met de overeengekomen termijn, tenzij de verlenging uiterlijk 30 dagen voor het verstrijken van de relevante contracttermijn wordt tegengehouden.</p>
          <p>Contracten die voor onbepaalde tijd zijn afgesloten, kunnen door beide partijen schriftelijk op elk moment worden beëindigd met een opzegtermijn van drie maanden tot het einde van de maand.</p>
          <p>Het recht om het contract om gegronde redenen te beëindigen blijft onaangetast. Een gegronde reden is met name gegeven als een van de partijen essentiële plichten en verplichtingen uit het contract of de toepasselijke wetgeving van de staat Denemarken schendt en de contractuele staat niet herstelt ondanks een schriftelijke waarschuwing van de andere partij binnen 7 dagen na ontvangst van de waarschuwing. De waarschuwing moet de schending van een verplichting of plicht specificeren en wijzen op de mogelijkheden van beëindiging om gegronde redenen. Essentiële contractuele verplichtingen zijn met name verplichtingen tot vertrouwelijkheid.</p>

          <p>Voor Matthias Kupperschmidt is een gegronde reden gegeven als</p>
          <p>de opening van insolventie- of faillissementsprocedures tegen de activa van de contractuele partner is aangevraagd</p>
          <ol>
            <li>elke andere verwijtbare schending van essentiële contractuele verplichtingen of plichten van een contractuele partner als deze ondanks een waarschuwing niet onmiddellijk zijn opgeheven.</li>
          </ol>
          <p>Totdat de beëindiging van kracht wordt, behoudt elke contractuele partner zijn aanspraak op vergoedingen en commissies die nog steeds zullen worden gemaakt. Tot dat moment zijn de contractuele partners ook verplicht hun contractuele verplichtingen na te komen. Alle verplichtingen die verder gaan dan de contracttermijn, met name die met betrekking tot gegevensbescherming en vertrouwelijkheid, blijven hierdoor onaangetast.</p>
          <p>Alle beëindigingen moeten schriftelijk of per e-mail worden gedaan.</p>

          <p>Paragraaf 6 Vergoeding en vervaldatums</p>
          <p>De overeengekomen vergoeding is verschuldigd aan het einde van het advies en/of de uitvoering van de maatregelen. Een acceptatie is niet vereist.</p>
          <p>Matthias Kupperschmidt heeft recht op een redelijke betaling al voor het einde van het advies en de uitvoering van de maatregelen. Bij twijfel is de redelijkheid gebaseerd op de reeds geleverde arbeidstijd. In het geval van permanente consultancy heeft Matthias Kupperschmidt het recht om diensten onmiddellijk na levering in rekening te brengen. De vergoeding is gebaseerd op het aanbod van Matthias Kupperschmidt.</p>

          <p>Paragraaf 7 Geldtransfers, betalingsvoorwaarden</p>
          <p>De vergoeding, of de verdeling van de verkoop, wordt altijd gedaan volgens de opgave in de individuele bestelling.</p>
          <p>Tenzij anders overeengekomen in de individuele bestelling, wordt de afrekening maandelijks door Matthias Kupperschmidt gedaan. Alle verkoopcijfers en vergoedingen zijn nettobedragen; BTW wordt afzonderlijk vermeld.</p>
          <p>Matthias Kupperschmidt heeft het recht om voorschotten van maximaal 100% van het ordervolume te eisen; voorschotten zijn verschuldigd bij het ondertekenen van het contract.</p>
          <p>In geval van late betaling heeft Matthias Kupperschmidt het recht om de contractuele partner vertragingsrente in rekening te brengen – terwijl hij zich het recht voorbehoudt om verdere schadevergoeding te eisen – ter hoogte van vijf procentpunten boven de respectieve basisrente. De vertragingsrente is lager als de contractuele partner een lagere schade bewijst.</p>
          <p>De contractuele partner heeft alleen een retentierecht of recht op compensatie voor zover de vorderingen van de contractuele partner onbetwist en juridisch vastgesteld zijn.</p>

          <p>Paragraaf 8 Eigendomsrechten en auteursrechtbescherming, gebruiksrechten</p>
          <p>De auteursrechten en andere eigendomsrechten op de door Matthias Kupperschmidt geleverde diensten blijven bij Matthias Kupperschmidt.</p>
          <p>Matthias Kupperschmidt verleent de contractuele partner niet-exclusieve, tijdelijke en territoriaal onbeperkte gebruiksrechten voor de exploitatie van de volledig geleverde dienst volgens het contract (werkresultaten).</p>
          <p>De contractuele partner is echter niet gerechtigd om de geleverde inhoud en informatie aan derden te verkopen of anderszins aan derden beschikbaar te stellen buiten hun beoogde doel.</p>
          <p>Wijzigingen aan de door Matthias Kupperschmidt geleverde diensten die door de contractuele partner of een door hem ingeschakelde derde worden uitgevoerd, zijn alleen toegestaan met uitdrukkelijke toestemming van Matthias Kupperschmidt.</p>
          <p>De uitoefening van de gebruiksrechten is alleen toegestaan als de contractuele partner op dat moment niet in gebreke is met een betaling.</p>
          <p>Op basis van overeenkomsten met werknemers, agenten en eventuele derden met rechthebbenden zorgt Matthias Kupperschmidt ervoor dat de contractuele partner vrijelijk de contractuele gebruiksrechten kan uitoefenen.</p>

          <p>Paragraaf 9 Garantie en aansprakelijkheid</p>
          <p>Matthias Kupperschmidt zal zijn diensten binnen het kader van zijn technische mogelijkheden uitvoeren en biedt geen garantie voor ononderbroken en/of constant foutloze beschikbaarheid van zijn diensten; in het bijzonder niet voor lijnstoringen en/of verbindingsstoringen, hardware- en/of softwarefouten of voor handelingen van derden (bijv. virussen of "denial of service" aanvallen). De contractpartijen komen overeen dat het optreden van dergelijke onderbrekingen en/of fouten geen recht geeft op herstel of schadevergoeding van welke aard dan ook.</p>
          <p>Gebreken zijn afwijkingen van de dienstbeschrijving die het contractuele gebruik beperken. Eventuele gebreken moeten gedetailleerd aan Matthias Kupperschmidt worden gemeld. De partijen zullen samenwerken bij de analyse en oplossing van gebreken. Herstel wordt uitgevoerd binnen het kader van de aansprakelijkheid voor gebreken en brengt geen kosten met zich mee voor de contractuele partner.</p>
          <p>De contractuele partner moet Matthias Kupperschmidt een redelijke termijn voor herstel toestaan. Als het herstel niet succesvol is, zelfs niet binnen een tweede redelijke respijtperiode (minstens echter binnen 15 werkdagen), heeft de contractuele partner het recht om een verlaging van de betaling of schadevergoeding wegens niet-nakoming te eisen, mits hij een dergelijke stap heeft aangekondigd bij het vaststellen van de respijtperiode.</p>
          <p>Vorderingen wegens gebreken van de contractuele partner kunnen niet meer worden ingediend als hij zonder voorafgaande schriftelijke toestemming wijzigingen aan de diensten heeft aangebracht of door derden heeft laten aanbrengen, tenzij de contractuele partner kan bewijzen dat de gebreken niet het gevolg zijn van deze wijzigingen of dat deze wijzigingen de analyse en oplossing van gebreken slechts in geringe mate belemmeren.</p>
          <p>Vorderingen wegens gebreken zijn in principe uitgesloten als de contractuele partner de diensten gebruikt onder gebruiksvoorwaarden die afwijken van die in de overeenkomst zijn gespecificeerd. Het is de verantwoordelijkheid van de contractuele partner om te bewijzen dat het gebrek niet is veroorzaakt door een dergelijke afwijking.</p>
          <p>Vorderingen zijn uitgesloten voor schade op grond van de Deense verkoopwet vanwege gebreken aan het begin van het contract waarvoor Matthias Kupperschmidt niet verantwoordelijk is, evenals vanwege alleen nalatige gebreken.</p>
          <p>Vorderingen wegens gebreken verjaren één jaar na acceptatie; in het geval van diensten die niet kunnen of niet hoeven te worden geaccepteerd, één jaar na levering/overname of na kennisgeving van voltooiing en/of levering van de dienst.</p>
          <p>Matthias Kupperschmidt is niet aansprakelijk voor technische storingen waarvan de oorzaken niet binnen de verantwoordelijkheid van Matthias Kupperschmidt vallen of voor schade veroorzaakt door overmacht. In uitzonderlijke gevallen kan het voorkomen dat individuele gegevens, diensten of andere technische of feitelijke activiteiten niet kunnen worden vastgelegd of correct worden gefactureerd door Matthias Kupperschmidt. Elke vordering op basis hiervan - met name vorderingen wegens schade of waardevervanging - tegen Matthias Kupperschmidt door de contractuele partner is uitgesloten.</p>
          <p>Matthias Kupperschmidt is alleen aansprakelijk voor schade als gevolg van de schending van essentiële contractuele verplichtingen of vanwege de afwezigheid van gegarandeerde kenmerken en eigenschappen. Verder is Matthias Kupperschmidt alleen aansprakelijk als de schade is veroorzaakt door grove nalatigheid of opzettelijke bedoelingen.</p>
          <p>Matthias Kupperschmidt is aansprakelijk in geval van een licht nalatige schending van een essentiële contractuele verplichting en, in geval van niet-contractuele aansprakelijkheid, alleen in geval van verlies van leven, lichamelijk letsel en schade aan de gezondheid, bij schending van garanties, essentiële contractuele verplichtingen evenals in overeenstemming met de productaansprakelijkheidswet alleen tot het bedrag dat van toepassing is op typische schade die voorzienbaar was bij het ondertekenen van het contract. De schade wordt berekend op basis van het gemiddelde maandelijkse ordervolume van de afgelopen zes maanden. In het bijzonder is aansprakelijkheid voor licht nalatige schending van essentiële contractuele verplichtingen in het geval van winstverlies, indirecte schade en gevolgschade in principe uitgesloten.</p>
          <p>Aansprakelijkheid voor gevolgschade, indirecte schade en/of winstverlies is uitgesloten, tenzij in geval van opzettelijk gedrag. Wat betreft bedragen, is de aansprakelijkheid beperkt tot de verwachte normale schade in geval van verlies binnen Denemarken; maximaal echter tot de totale vergoeding die door de contractuele partner volgens de respectieve opdracht moet worden betaald. Deze beperking van aansprakelijkheid is op dezelfde manier van toepassing op schade veroorzaakt door grove nalatigheid of opzet van medewerkers of agenten van Matthias Kupperschmidt.</p>
          <p>In het geval dat de contractuele partner wijzigingen aan de contractuele dienst of de omgeving ervan heeft aangebracht of door derden heeft laten aanbrengen, vervallen alle vorderingen van de contractuele partner uit de bovenstaande bepalingen, tenzij het gebrek niet geheel of gedeeltelijk is veroorzaakt door de door de contractuele partner aangebrachte wijzigingen en het herstel niet wordt bemoeilijkt door de wijzigingen.</p>
          <p>Deze beperkingen van aansprakelijkheid zijn mutatis mutandis van toepassing op alle medewerkers en agenten van Matthias Kupperschmidt. Elke aansprakelijkheid van Matthias Kupperschmidt op grond van de productaansprakelijkheidswet blijft onaangetast.</p>
          <p>Alle vorderingen wegens schade of andere vorderingen die tegen Matthias Kupperschmidt kunnen worden ingediend vanwege de eigenschappen en staat of gebreken van de dienst vallen onder de verjaringstermijn van 6 (in woorden: zes) maanden na het einde van het betreffende contract.</p>
          <p>De contractuele partner is aansprakelijk jegens Matthias Kupperschmidt voor de inhoud, documenten, links en andere materialen (hierna "documenten" genoemd) die door hem zijn verstrekt en beschikbaar zijn gesteld aan Matthias Kupperschmidt voor de uitvoering van het samenwerkingsproject.</p>
          <p>Indien nodig zal de contractuele partner op eigen kosten controleren of de door Matthias Kupperschmidt geleverde diensten voldoen aan de relevante wettelijke bepalingen. Matthias Kupperschmidt zal een dergelijke externe juridische controle alleen initiëren op uitdrukkelijk schriftelijk verzoek van de contractuele partner; de bijbehorende kosten zijn voor rekening van de contractuele partner.</p>
          <p>In het geval dat derden vorderingen tegen Matthias Kupperschmidt indienen wegens schending van auteursrechten en/of schending van relevante wettelijke bepalingen die hebben plaatsgevonden binnen de sfeer van plichten van de contractuele partner, zal de contractuele partner alles in zijn macht doen om Matthias Kupperschmidt te verdedigen tegen de ingediende vorderingen of rechten en de vermeende schending te elimineren. De contractuele partner is ook aansprakelijk jegens Matthias Kupperschmidt voor eventuele schade veroorzaakt door derden die is ontstaan als gevolg van de opdracht die gedeeltelijk, foutief, vertraagd, laat of helemaal niet is uitgevoerd. Hetzelfde geldt voor eventuele agenten van de contractuele partner. De contractuele partner draagt de bewijslast voor de juiste uitvoering van de opdracht.</p>
          <p>De contractuele partner vrijwaart Matthias Kupperschmidt van alle vorderingen wegens schade, aansprakelijkheidsclaims en kosten die Matthias Kupperschmidt maakt doordat een vordering tegen hem wordt ingediend waarbij wordt gesteld dat de documenten van de contractuele partner inbreuk maken op het mededingingsrecht, inbreuk maken op industriële eigendomsrechten van derden of andere wetten en voorschriften. In het bijzonder zal de contractuele partner Matthias Kupperschmidt, op eerste verzoek, onmiddellijk vrijwaren van eventuele vorderingen van derden die tegen Matthias Kupperschmidt worden ingediend vanwege de gebruikte inhoud of een aanbod dat via een hyperlink is gelinkt, met name onder de Deense Wet Bescherming Persoonsgegevens (DDPA) en de Algemene Verordening Gegevensbescherming (AVG) van de EU en zal op verzoek alle kosten terugbetalen die Matthias Kupperschmidt maakt als gevolg van gepaste juridische stappen.</p>

          <p>Paragraaf 10 Toelaatbaarheid van websites</p>
          <p>morefire controleert niet of de inhoud van de websites van de klant inbreuk maakt op de rechten van derden of in overeenstemming is met de richtlijnen van de individuele zoekmachine-exploitanten. De klant is als enige verantwoordelijk voor eventuele straffen door derden.</p>
          <p>De klant is ook verantwoordelijk voor de juridische toelaatbaarheid van de inhoud van zijn websites en voor de door hem verstrekte informatie, inclusief de zoektermen of keywords; hetzelfde geldt voor de bescherming van de rechten van derden, in het bijzonder met betrekking tot auteursrecht, mededingingsrecht en strafrecht.</p>

          <p>Paragraaf 11 Dienstverleningsovereenkomst</p>
          <p>Werknemers die worden ingezet in het kader van dienstverleningsovereenkomsten mogen niet door de klant worden benaderd voor een nieuwe functie binnen een periode van 24 maanden na het toekennen van de opdracht. Indien een dergelijke benadering binnen deze periode plaatsvindt, heeft de opdrachtnemer recht op een eenmalige betaling van €75.000.</p>

          <p>Paragraaf 12 Gegevensbescherming</p>
          <p>Zonder een aanvullende toestemmingsverklaring worden persoonsgegevens – tenzij anders aangegeven – alleen verzameld, verwerkt en gebruikt voor het doel van het verlenen van de diensten, het gebruik van de diensten en, indien van toepassing, voor facturering.</p>
          <p>De contractuele partner is verplicht om alle toepasselijke bepalingen inzake gegevensbescherming na te leven, met name de bepalingen van de Deense Wet Bescherming Persoonsgegevens (DDPA) en de Algemene Verordening Gegevensbescherming (AVG) van de EU.</p>
          <p>De contractuele partner garandeert dat hij gegevens die hij mogelijk heeft verzameld uitsluitend zal gebruiken voor het opgegeven doel en voor marktonderzoek en reclamedoeleinden binnen het bedrijf, voor zover dit nodig is voor de uitvoering van de gesloten transacties en het onderhoud van de daaruit voortvloeiende gebruikersrelatie en indien dit wettelijk is toegestaan en gewenst door de gebruiker. Het doorverkopen van gegevens aan derden door de contractuele partner is uitgesloten, en er zullen gerechtelijke stappen worden ondernomen.</p>

          <p>Paragraaf 13 Vertrouwelijkheid en geheimhouding</p>
          <p>De contractuele partner is verplicht om de documenten, kennis en ervaring die hij van Matthias Kupperschmidt heeft verkregen uitsluitend te gebruiken voor de doeleinden van dit contract. Bovendien komen de contractpartijen overeen dat zij tegenover derden vertrouwelijkheid zullen betrachten met betrekking tot de inhoud van de gemaakte afspraken en alle kennis die tijdens de uitvoering daarvan is verkregen. De contractuele partner is verplicht om met Matthias Kupperschmidt te overleggen indien er twijfel bestaat of bepaalde informatie in een specifiek geval vertrouwelijk moet worden behandeld.</p>
          <p>In geval van mogelijke onderaanneming zal de contractuele partner de betreffende onderaannemer verplichten tot verplichtingen in de zin van deze bepaling.</p>
          <p>De contractuele partner zal ervoor zorgen dat deze verplichting tot geheimhouding wordt nageleefd door zijn werknemers en alle derden die bij de uitvoering van het contract betrokken zijn en toegang hebben tot de in de eerste paragraaf genoemde documenten en objecten.</p>


          <p>Paragraaf 14 Wijzigingen</p>
          <p>Matthias Kupperschmidt zal zijn diensten verlenen in overeenstemming met de respectieve productbeschrijvingen. Matthias Kupperschmidt behoudt zich het recht voor om de diensten geheel of gedeeltelijk te wijzigen, uit te breiden of aan te passen, of om het type en de inhoud van individuele producten en diensten die worden aangeboden in het kader van de levering van de diensten, op elk moment en herhaaldelijk te wijzigen, uit te breiden of aan te passen voor de verbetering van het aanbod, met name de functies, het ontwerp en de technische ontwikkeling. De contractuele partner zal tijdig per e-mail worden geïnformeerd over belangrijke wijzigingen in de producten. De nieuwe versie van de productbeschrijvingen zal worden bijgevoegd. Als de contractuele partner niet akkoord gaat met de wijziging, heeft hij het recht om het contract met Matthias Kupperschmidt met onmiddellijke ingang te beëindigen. Als de contractuele partner niet binnen twee weken bezwaar maakt tegen de wijziging, worden de meegedeelde wijzigingen als de nieuwe voorwaarden beschouwd.</p>
          <p>Bovendien heeft Matthias Kupperschmidt het recht om individuele functies die worden aangeboden als onderdeel van de diensten stop te zetten, met name in geval van gewijzigde wettelijke vereisten, langdurige verliezen, technische moeilijkheden of bij misbruik door klanten, contractuele partners of derden.</p>
          <p>Matthias Kupperschmidt behoudt zich ook uitdrukkelijk het recht voor om prijswijzigingen door te voeren, waarvan Matthias Kupperschmidt de contractuele partner binnen een redelijke termijn op de hoogte zal stellen.</p>
          <p>Matthias Kupperschmidt behoudt zich ook het recht voor om deze AV te wijzigen of aan te vullen, met name in het geval dat nieuwe diensten worden geïntroduceerd of wijzigingen noodzakelijk zijn vanwege wettelijke bepalingen. Matthias Kupperschmidt zal de contractuele partner op de hoogte stellen van wijzigingen of aanvullingen op de AV. Als de contractuele partner niet akkoord gaat met de wijzigingen, kan hij binnen twee weken nadat deze schriftelijk aan hem bekend zijn gemaakt, bezwaar maken.</p>
          <p>In het geval dat de contractuele partner bezwaar maakt tegen wijzigingen in de diensten, deze AV of de bijzondere voorwaarden voor de individuele producten, heeft Matthias Kupperschmidt het recht om zonder opzegtermijn contracten die onder deze AV zijn afgesloten, te beëindigen. Als de contractuele partner geen bezwaar maakt, wordt zijn toestemming geacht te zijn gegeven na het verstrijken van de bovengenoemde termijnen. Matthias Kupperschmidt zal in de kennisgeving van de wijziging van de AV de duur van de termijn en de betekenis van het verstrijken ervan vermelden.</p>

          <p>Paragraaf 15 Slotbepalingen</p>
          <p>Het verzenden van meldingen naar Matthias Kupperschmidt per e-mail voldoet alleen aan de vereiste van schriftelijke vorm als dit uitdrukkelijk is bepaald in deze gebruiksvoorwaarden en als Matthias Kupperschmidt hier vooraf uitdrukkelijk mee heeft ingestemd.</p>
          <p>Een overdracht van contractuele rechten en verplichtingen door de contractuele partner aan een derde is alleen toegestaan met voorafgaande schriftelijke toestemming van Matthias Kupperschmidt.</p>
          <p>De contractuele partner heeft alleen een retentierecht met betrekking tot onbetwiste of juridisch vastgestelde vorderingen.</p>
          <p>Er zijn geen bijkomende afspraken. Alle overeenkomsten tussen de partijen moeten schriftelijk worden vastgelegd. Dit geldt ook voor de opheffing van de eis van schriftelijke vorm.</p>
          <p>De contractuele partner zal Matthias Kupperschmidt tijdig informeren over aanstaande insolventieprocedures om de nakoming van de contractuele diensten en verplichtingen te waarborgen of om een consensuele beëindiging van het contract mogelijk te maken.</p>
          <p>Indien individuele bepalingen van de Algemene Voorwaarden geheel of gedeeltelijk nietig zijn of een leemte bevatten, blijft de geldigheid van de overige bepalingen of delen daarvan onaangetast. In een dergelijk geval zijn de partijen verplicht mee te werken aan de totstandkoming van nieuwe bepalingen, waarmee een commercieel resultaat wordt bereikt met juridische werking die het dichtst in de buurt komt van de nietige of onvolledige bepaling, met behoud van wederzijdse belangen.</p>
          <p>Plaats van uitvoering voor beide partijen is de vestigingsplaats van Matthias Kupperschmidt.</p>
          <p>Het recht van de staat Denemarken is uitsluitend van toepassing, met uitsluiting van het Verdrag der Verenigde Naties inzake internationale koopovereenkomsten betreffende roerende zaken (CISG) en verwijzingen naar buitenlands recht.</p>
          <p>Indien de klant een handelaar is, een rechtspersoon naar publiek recht of een speciaal fonds naar publiek recht, of als hij geen vestiging in Denemarken heeft, is de exclusieve bevoegde rechtbank Kopenhagen, Denemarken. Matthias Kupperschmidt is ook gerechtigd om een rechtszaak aan te spannen op de vestigingsplaats van de contractuele partner.</p>

          <p>Paragraaf 13 Geschillenbeslechting</p>
          <p>De Europese Commissie heeft een platform opgezet voor online geschillenbeslechting. Het is toegankelijk via: <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>.</p>
          <p>Consumenten kunnen het platform gebruiken voor geschillenbeslechting. Matthias Kupperschmidt is niet bereid of verplicht om deel te nemen aan geschillenbeslechtingsprocedures voor een consumenten arbitragecommissie, tenzij er een wettelijke verplichting is om deel te nemen.</p>
          <p>Kopenhagen, 1 februari 2019</p>



        </MainContent>
      </React.Fragment>

    </Layout>
  );
};
export default LegalNotice;
